import Modal from "@entities/payment/components/Forms/modal";
import {
  $isRestaurantOpen
} from "@features/choose-dishes/models";
import { $rus } from "@features/choose-dishes/models";
import { useStore } from "effector-react";
import { useState, useEffect } from "react";
import { $isAdressModalOpen, $isShopOnlyClosedModalOpen, setShopOnlyClosedModalOpen } from "./address-modal";

export function ShopOnlyClosedModal() {
  const isAddressOpen = useStore($isAdressModalOpen);

  const isRus = useStore($rus);
  const isOpen = useStore($isShopOnlyClosedModalOpen);

  return (
    <Modal
      showClose
      open={!isAddressOpen && isOpen}
      onClose={() => setShopOnlyClosedModalOpen(false)}
    >
      <article className="flex flex-col justify-items-start items-start p-5 sm:p-8 bg-light min-h-screen md:min-h-0 max-w-3xl md:rounded-2xl">
        <div>
          <div className="text-body font-bold text-xl mb-4 sm:mb-6 pr-10">Прием заказов с сайта недоступен</div>
          <p>По техническим причинам прием заказов с сайта недоступен до <strong>03.01.2025</strong>.</p>
          <p>Вы можете разместить свой заказ, позвонив или написав напрямую нам в ресторан по номеру <a href="tel:+7 (977) 456 2221" className="text-body font-bold">+7 (977) 456 2221</a></p>
        </div>
      </article>
    </Modal>
  );
}
